import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';
import { SiLangchain, SiReact, SiRubyonrails } from "react-icons/si";
import { TbBrandFlutter, TbBrandWebflow } from "react-icons/tb";


const labelsFirst = [
    "React",
    "TypeScript",
    "JavaScript",
    "HTML5",
    "CSS3",
    "SASS",
    "Flask",
    "Python",
    "SQL",
    "PostgreSQL",
    "Postman"
];

const labelsSecond = [
    "Git",
    "GitHub Actions",
    "Docker",
    "AWS",
    "Azure",
    "Linux",
    "Snowflake",
    "Pandas",
    "Selenium",
];

const labelsThird = [
    "OpenAI",
    "Groq",
    "LangChain",
    "Qdrant",
    "Hugging Face",
    "LlamaIndex",
    "Streamlit",
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <SiRubyonrails size={50} style={{marginRight: '30px'}}/>
                    <SiReact size={50}/>
                    <h3>Full Stack Web Development</h3>
                    <p>More than 5 years of experience building scalable, secure, and performant web applications using Ruby on Rails and React.</p>
                </div>

                <div className="skill">
                    <TbBrandFlutter size={50} style={{marginRight: '30px'}}/>
                    <TbBrandWebflow size={50} style={{marginRight: '30px'}}/>
                    <span style={{color: 'white'}}>...</span>
                    <h3>No-code Development</h3>
                    <p>For smaller projects, I leverage Webflow and FlutterFlow, Bubble, to build fast, responsive, and scalable web and mobile applications.</p>
                </div>

                <div className="skill">
                    <SiLangchain size={50} style={{marginRight: '30px'}}/>
                    <h3>GenAI & LLM</h3>
                    <p>CTO at Drakkar AI, I worked with industry leaders to implement GenAI solutions to empower intelligent decision making.</p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;