import React from "react";
import mock01 from '../assets/images/mock01.png';
import mock02 from '../assets/images/mock02.png';
import mock03 from '../assets/images/mock03.png';
import mock04 from '../assets/images/mock04.png';
import mock05 from '../assets/images/mock05.png';
import mock06 from '../assets/images/mock06.png';
import mock07 from '../assets/images/mock07.png';
import mock08 from '../assets/images/mock08.png';
import mock09 from '../assets/images/mock09.png';
import mock10 from '../assets/images/mock10.png';
import mock11 from '../assets/images/mock11.png';
import mock12 from '../assets/images/mock12.png';
import mock13 from '../assets/images/mock13.png';
import mock14 from '../assets/images/mock14.png';
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>Portfolio</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://www.groupeseb.com/en" target="_blank" rel="noreferrer"><img src={mock11} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.groupeseb.com/en" target="_blank" rel="noreferrer"><h2>Seb AI</h2></a>
                    <p>Developed the app to create cooking recipes using AI.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://app.langflip.com/" target="_blank" rel="noreferrer"><img src={mock12} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://app.langflip.com/" target="_blank" rel="noreferrer"><h2>Langflip</h2></a>
                    <p>Developed using FlutterFlow a platform to translate videos into multiple languages.</p>
                </div>
                </div>
            <div className="project">
                <a href="https://www.robindesfermes.ch/" target="_blank" rel="noreferrer"><img src={mock10} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.robindesfermes.ch/" target="_blank" rel="noreferrer"><h2>Robin des fermes</h2></a>
                    <p>Developed the whole marketplace from scratch using Ruby on Rails.</p>
            </div>
            </div>
            <div className="project">
                <a href="https://www.solike.review/" target="_blank" rel="noreferrer"><img src={mock09} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.solike.review/" target="_blank" rel="noreferrer"><h2>Solike</h2></a>
                    <p>Developed a chrome extension to answer using AI online reviews for a local business owner.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://ffroller-skateboard.fr/" target="_blank" rel="noreferrer"><img src={mock08} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://ffroller-skateboard.fr/" target="_blank" rel="noreferrer"><h2>FFRS</h2></a>
                    <p>Developed using Ruby on Rails a platform to help coaches and skateboarders to track their progress before the olympic games.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.igere.ch/" target="_blank" rel="noreferrer"><img src={mock07} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.igere.ch/" target="_blank" rel="noreferrer"><h2>Igere</h2></a>
                    <p>Developped using NextJS and React a platform to help property managers to manage their tenants.</p>
                </div>
            </div>
            <div className="project">
                <a href="" target="_blank" rel="noreferrer"><img src={mock06} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="" target="_blank" rel="noreferrer"><h2>Doctodeal</h2></a>
                    <p>Developped an inventory management system for a medical retailer using Airtable, Make, Google Apps Script and Umso.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.bybambou.com/" target="_blank" rel="noreferrer"><img src={mock05} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.bybambou.com/" target="_blank" rel="noreferrer"><h2>ByBambou</h2></a>
                    <p>Developped an inventory management system for a clothing retailer using Airtable, Make, Google Apps Script and Shopify.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://playse.us/" target="_blank" rel="noreferrer"><img src={mock04} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://playse.us/" target="_blank" rel="noreferrer"><h2>Playse</h2></a>
                    <p>Developped in collaboration with Blaise Matuidi a platform to find football coaches near them. Using Ruby on Rails.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.drakkar.io/" target="_blank" rel="noreferrer"><img src={mock03} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.drakkar.io/" target="_blank" rel="noreferrer"><h2>Drakkar</h2></a>
                    <p>Developped the Webflow website for my previous web development agency.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.kosmoy.com/" target="_blank" rel="noreferrer"><img src={mock02} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.kosmoy.com/" target="_blank" rel="noreferrer"><h2>Kosmoy</h2></a>
                    <p>Developped the whole app using FlutterFlow.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.333go.com/" target="_blank" rel="noreferrer"><img src={mock01} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.333go.com/" target="_blank" rel="noreferrer"><h2>333 GO</h2></a>
                    <p>Developed the whole app using React Native and the Ruby on Rails API.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://www.kinshipedu.fr" target="_blank" rel="noreferrer"><img src={mock13} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://www.kinshipedu.fr" target="_blank" rel="noreferrer"><h2>Kinship</h2></a>
                    <p>Developed using Ruby on Rails a social network for school teachers and students.</p>
                </div>
            </div>
            <div className="project">
                <a href="https://realchat.ai/" target="_blank" rel="noreferrer"><img src={mock14} className="zoom" alt="thumbnail" width="100%"/></a>
                <div>
                    <a href="https://realchat.ai/" target="_blank" rel="noreferrer"><h2>Realchat</h2></a>
                    <p>Developed using Bubble a platform for companies to automate the customer support using AI.</p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Project;